import * as basicLightbox from 'basiclightbox'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Alpine from 'alpinejs'
import Swiper from 'swiper/bundle';
const $ = s => Array.from(document.querySelectorAll(s));
const $$ = s => document.querySelector(s);
import barba from '@barba/core';
window.Alpine = Alpine
import 'lazysizes';

Alpine.start()
gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;

import Splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

function calculateDistance(element, mouseX, mouseY) {
  // Get the bounding rectangle of the element
  const rect = element.getBoundingClientRect();

  // Calculate the center of the element
  const centerX = rect.left + rect.width / 2;
  const centerY = rect.top + rect.height / 2;

  // Calculate the distance between the mouse and the center of the element
  const distX = mouseX - centerX;
  const distY = mouseY - centerY;

  // Use the Pythagorean theorem to calculate the distance
  const distance = Math.sqrt(distX * distX + distY * distY);

  return distance;
}



window.addEventListener('mousemove', function (e) {

  mX = e.pageX;
  mY = e.pageY;
  const chars = document.querySelectorAll('.char');

  chars.forEach(char => {
    const distance = calculateDistance(char, e.clientX, e.clientY);

    let realFontSize = window.getComputedStyle(char).fontSize;
    realFontSize = realFontSize.replace('px', '');
    let maxStrokeWidth = (realFontSize * 10) / 45;
    strokeWidthClamp = gsap.utils.clamp(maxStrokeWidth / 4, maxStrokeWidth);
    var distanceThreshold = gsap.utils.mapRange(0, 80, 0, 150, realFontSize);
    strokeWidth = gsap.utils.mapRange(0, distanceThreshold, 100, 10, distance);
    gsap.to(char, { duration: 0.3, webkitTextStrokeWidth: strokeWidthClamp(strokeWidth), overwrite: true });
  });

});

// get main container
const main = document.querySelector('main');
gsap.fromTo(main, { opacity: 0 }, { opacity: 1, duration: 1 });


function init() {


  Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
    element.onclick = (e) => {
      e.preventDefault();
      basicLightbox.create(`<img src="${element.href}">`).show();
    };
  });

  Splitting();

  const swipers = [];

  Array.from(document.querySelectorAll(".swiper")).forEach(element => {

    const name = element.dataset.name;
    const slidesPerView = element.dataset.slidesperview;
    const mobileSlidesperview = element.dataset.mobileslidesperview;
    const section = element.closest('section');
    const pagination = element.querySelector(".swiper-pagination");

    // check if elment as data parralax
    const parallax = element.hasAttribute("data-parallax")
    const centered = element.hasAttribute("data-centered")

    // create a swiper instance
    const swiper = new Swiper(element, {
      // configure Swiper to use modules
      //  modules: [Navigation, Pagination, Autoplay, Parallax, Mousewheel, EffectFade],
      slidesPerView: 1,
      spaceBetween: 0,
      effect: "fade",
      fadeEffect: { crossFade: true },
      speed: 300,
      autoplay: {
        delay: 2000,
      },
      loop: true,
      shortSwipes: true,
      centeredSlides: centered,
      centeredSlidesBounds: centered,
      centerInsufficientSlides: centered,
      grabCursor: true,
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: element.querySelector(".swiper-button-next"),
        prevEl: element.querySelector(".swiper-button-prev"),
        disabledClass: 'disabled',
      },

      on: {
        init: function () {
          // if (this.realIndex != 0){
        },
        transitionStart: function (e) {
          // if (e.realIndex != 0){
          //   $$('#title-wrapper').classList.add('hidden');
          // }else{
          //   $$('#title-wrapper').classList.remove('hidden');
          // }
        },
        // sliderMove: function () {
        //   lenis.stop()
        // },
        transitionEnd: function (e) {
          console.log(e.activeIndex)


        },
      },

      //  watchSlidesProgress: true,
    });

    // add mousemove eventlistener to the swiper next button
    const nextButton = element.querySelector(".swiper-button-next");
    const prevButton = element.querySelector(".swiper-button-prev");
    const nextLabel = element.querySelector('#swiper-label-next');
    const prevLabel = element.querySelector('#swiper-label-prev');

    if (nextButton) {
      nextButton.addEventListener('mousemove', function (e) {
        console.log('mousemove on next')
        prevLabel.style.display = 'none';
        nextLabel.style.display = 'block';
        const x = e.clientX - this.offsetLeft - nextLabel.offsetWidth / 2;
        const y = e.clientY - this.offsetTop - nextLabel.offsetHeight / 2;
        gsap.to(nextLabel, { x: x, y: y, duration: 0 })
      });

      nextButton.addEventListener('mouseleave', function (e) {
        nextLabel.style.display = 'none';
      });
    }

    if (prevButton) {

      prevButton.addEventListener('mousemove', function (e) {
        prevLabel.style.display = 'block';
        nextLabel.style.display = 'none';
        const x = e.clientX - this.offsetLeft - prevLabel.offsetWidth / 2;
        const y = e.clientY - this.offsetTop - prevLabel.offsetHeight / 2;
        gsap.to(prevLabel, { x: x, y: y, duration: 0 })
      });

      prevButton.addEventListener('mouseleave', function (e) {
        prevLabel.style.display = 'none';
      });

    }

    swiper['name'] = name;

    swipers.push(swiper);

  });



  ScrollTrigger.killAll();

  gsap.utils.toArray("#rubriques li").forEach(
    (el) => {
      console.log(el)
      gsap.to(el.querySelector('.bg'),
        {
          scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "+=20",
            scrub: 2,
            pinSpacing: false,
            markers: false
          },
          opacity: 1,
        }
      );
    })

  ScrollTrigger.refresh()


}


init();


barba.init({
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  transitions: [{
    name: 'opacity-transition',
    leave(data) {
      console.log(data)
      gsap.to(data.current.container, {
        opacity: 0,
        duration: 1
      });
      // init()

      if (document.body.classList.contains('on-transition')) {
        return;
      }

    },
    enter(data) {
      // scroll window to top
      gsap.fromTo(data.next.container, {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.3,
        delay: 0.3
      });
      // init()

      // gsap.to(window, { duration: 0.3, scrollTo: 0 });
      // window.dispatchEvent(new Event('scroll'));


    },
    afterLeave(data) {

    },


    after(data) {
      // if (data.next.container.id === 'home') {
      //   initPage();
      init()

      window.scrollTo(0, 0);



    }

  }]
});
